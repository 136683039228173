<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} suppliers</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} suppliers that match '{{query}}'</span>
      <router-link
        v-if="can(uiPermissions.SUPPLIERS_CREATE)"
        to="/suppliers/new"
        data-test="btn-new-supplier"
        class="btn btn-primary ml-auto">
        New supplier
      </router-link>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table data-test="table-suppliers" class="table table-hover table-outline table-vcenter card-table">
                <thead>
                  <tr>
                    <th class="w-1"></th>
                    <th>
                      <h-table-sorter :value="sortableFields.name" @sorting="direction => getSort(direction, 'name')">
                        Name
                      </h-table-sorter>
                    </th>
                    <th>
                      <h-table-sorter
                        :value="sortableFields.updated_at"
                        @sorting="direction => getSort(direction, 'updated_at')">
                        Last updated
                      </h-table-sorter>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                    </td>
                    <td class="text-nowrap">
                      <div class="d-flex align-items-center">
                        <i
                          v-if="item.trading_term_expiring"
                          v-b-tooltip.hover
                          class="fe fe-alert-circle text-danger mr-1"
                          title="Trading term is going to expire soon."></i>
                        <router-link v-if="can(uiPermissions.SUPPLIERS_VIEW)" :to="`/suppliers/${item.id}`">{{item.name}}</router-link>
                        <span v-else>{{item.name}}</span>
                      </div>
                    </td>
                    <td class="text-nowrap">{{item.updated_at}}</td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="100%" class="text-center">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import HTableSorter from '@/components/ui/HTableSorter';
import list from '@/mixins/list';
import {suppliers} from '@/services';

export default {
  components: {
    HTableSorter,
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      sortableFields: {
        name: 'asc',
        updated_at: null,
      },
    };
  },
  computed: {
    sorts() {
      let sort = {};

      Object.keys(this.sortableFields).forEach(key => {
        const value = this.sortableFields[key];
        if (value) {
          sort = {
            column: key,
            direction: value,
          };
        }
      });

      return sort;
    },
  },
  methods: {
    fetchData(page) {
      return suppliers.getByParameters({
        ...this.sorts,
        limit: this.limit,
        page,
        query: this.query,
      });
    },
    getSort(direction, field) {
      Object.keys(this.sortableFields).forEach(field => {
        this.sortableFields[field] = null;
      });

      this.sortableFields[field] = direction;

      this.refresh();
    },
  },
};

</script>
